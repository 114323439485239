<template>
  <b-container>
    <b-card>
      <b-card-header>
        <b-card-title>Gültige Projektionen</b-card-title>
      </b-card-header>
      <b-card-body>
        <p>Die Name in folgender Tabelle ist die eingestellte Projektion im RiPano Export.
          Die Werte in der Proj4 Spalte sind die Projektionsparameter nach WGS84.
          Werden andere Namen für das Koordinatensystem verwendet, schlägt der Import fehl.
          </p>

        <b-table
          :items="projTable"
        ></b-table>
      </b-card-body>
    </b-card>
  </b-container>
</template>
<script>
import request from '../util/request';

export default {
  data() {
    return {
      projections: {},
      projTable: [],
    }
  },
  mounted: function () {
    this.getProjectionList();
  },
  methods: {
    getProjectionList: function () {
      request.get("projects/projections").then((projections) => {
        console.log("projections: ", projections.data);
        this.projections = projections.data;
        this.projTable = Object.keys(this.projections).map((key) => {
          return {
            name: key,
            proj4: this.projections[key],
          };
        });
      });
    },
  },
}
</script>
