import * as L from "leaflet";
// import request from "../util/request";
import "leaflet.markercluster";
import "leaflet-providers";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { layerControl, providerLayers } from "./layercontrol";
import ripano from "../util/ripano";

const projectIcon = L.icon({
  iconUrl: require("../assets/map-marker-4x.png"),
  iconSize: [30, 30],
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
});

const VldmMap = function (ele, opts) {
  this.vue = opts.Vue || {};
  this.layerCtrl = layerControl();
  this.map = new L.Map(ele);
  this.providers = providerLayers();
  for (const [key, value] of Object.entries(this.providers)) {
    this.layerCtrl.addBaseLayer(value, key);
  }
  if (opts.baseLayer) {
    this.map.addLayer(this.providers[opts.baseLayer]);
  } else {
    this.map.addLayer(this.providers["Geoland Ortho"]);
  }
  console.log("baselayer: ", this.providers);
  this.layerCtrl.addTo(this.map);
  this.map.setView([47.49, 13.39], 7);
  const logo = L.control({ position: "topleft" });
  logo.onAdd = function () {
    const div = L.DomUtil.create("div", "apg-logo");
    div.innerHTML = "<img src='/apg_logo_visual.png' alt='apg_logo' />";
    return div;
  };
  logo.addTo(this.map);
  this.projectLayer = L.featureGroup();
  this.layerCtrl.addOverlay(this.projectLayer, "Projekte");
  this.map.addLayer(this.projectLayer);
};

VldmMap.prototype.openPopup = function (id) {
  this.projectLayer.eachLayer((l) => {
    if (l.feature._id === id) {
      l.openPopup();
    }
  });
};

VldmMap.prototype.updateProject = function (project) {
  this.projectLayer.eachLayer((l) => {
    if (l.feature._id === project._id) {
      l.setStyle({
        color: project.markerIconColor,
        radius: project.markerIconRadius,
        fillColor: project.markerIconColor,
      });
    }
  });
};

VldmMap.prototype.addProjects = function (projects) {
  this.projectLayer.clearLayers();
  projects.forEach((p) => {
    // console.log("FEAT: ", feature, p);
    const popup = document.createElement("div");
    const ripanoLink = document.createElement("li");
    ripanoLink.innerHTML = `<a href="/ripano/?p=${p.ripanoProject}" target="ripano">Panorame Viewer - Virtuelle Anlage</a>`;
    const vldmLink = document.createElement("li");
    vldmLink.innerHTML = `<span title="/map/${p._id}">Luftbild</span>`;
    vldmLink.classList.add("link");
    const potreeLink = document.createElement("li");
    if (p.potreeUrl) {
      potreeLink.innerHTML = `<a href="${p.potreeUrl}" target="potree">3D Punktwolken Viewer</a>`;
    } else {
      potreeLink.innerHTML = `<span>3D Punktwolken Viewer</span>`;
    }
    const linkList = document.createElement("ul");
    linkList.appendChild(ripanoLink);
    linkList.appendChild(potreeLink);
    linkList.appendChild(vldmLink);
    const postal = p.postalAddress || "";
    popup.innerHTML = `<h4>${p.name}</h4>
            <p>${postal}</p>
            `;
    popup.appendChild(linkList);
    vldmLink.onclick = () => {
      this.vue.$router.push({ path: `/map/${p._id}` });
    };
    let marker;
    const latlng = L.latLng(
      p.location.geometry.coordinates[1],
      p.location.geometry.coordinates[0],
    );
    if (p.markerIcon == "circle") {
      const color = p.markerIconColor || "#ff0000";
      const radius = p.markerIconRadius || 10;
      marker = L.circleMarker(latlng, {
        radius: radius,
        color: color,
        fillColor: color,
        fillOpacity: 0.8,
      });
    } else {
      marker = L.marker(latlng, { icon: projectIcon });
    }
    marker.feature = p;
    marker.bindPopup(popup);
    // marker.on("click", () => {
    //   this.vue.$router.push({ path: `/map/${p._id}` });
    // });
    marker.addTo(this.projectLayer);
  });
};

VldmMap.prototype.setupMap = function (cfg) {
  console.log(cfg);
  return cfg.then((config) => {
    console.log("config data is ", config);
    if (config && config.data) {
      console.log("setting location: ", config);
      this.map.setView(config.data.map.initialMapView, 15);
      if (
        config.data.map.baseLayer &&
        providerLayers[config.data.map.baseLayer]
      ) {
        console.log("layerctl: ", this.layerCtrl);
        this.map.eachLayer((l) => {
          if (l.options && l.options.baseLayer === true) {
            this.map.removeLayer(l);
          }
        });
        console.log("set baselayer to ", config.data.map.baseLayer);
        this.map.addLayer(providerLayers[config.data.map.baseLayer]);
      } else {
        console.log("no baselayer config found, use OSM");
        this.map.addLayer(providerLayers["OpenStreetMap"]);
      }
    } else {
      console.log("config not found, use OSM", this.map);
      this.map.eachLayer((l) => {
        console.log("layerloop", l);
        if (l.options && l.options.baseLayer === true) {
          this.map.removeLayer(l);
          console.log("baselayer removed: ", l);
        }
      });
      this.map.addLayer(providerLayers["OpenStreetMap"]);
    }
  });
};

VldmMap.prototype.addScanPos = function (scanpositions) {
  var scanposIcon = L.icon({
    iconUrl: require("../assets/scanpos.png"),
    iconSize: [20, 30],
    iconAnchor: [10, 14],
    popupAnchor: [0, 0],
  });
  if (!scanpositions || !scanpositions.data || !scanpositions.data.length) {
    return;
  }
  const scans = L.markerClusterGroup({
    spiderfyOnMaxZoom: false,
    disableClusteringAtZoom: 17,
  });
  scans.addTo(this.map);
  this.layerCtrl.addOverlay(scans, "Scanpositionen");
  for (const scanpos of scanpositions.data) {
    L.geoJSON(scanpos, {
      pointToLayer: (feature, latlng) => {
        let panoLinks = [];
        let ripanoLinks = [];
        const props = feature.properties;
        if (props?.panos?.length) {
          props.panos.map((pano) => {
            const url = `/pano/${props.ripanoProject}/${props.scanPosition}/${pano.title}`;
            panoLinks.push(url);
            ripanoLinks.push(
              `/fullpano/${props.ripanoProject}/${props.scanPosition}/${pano.title}/60/0/90`,
            );
          });
        }
        const marker = L.marker(latlng, {
          icon: scanposIcon,
        }).on("click", () => {
          // this.vue.$router.push({ path: panoLinks[panoLinks.length - 1] });
          // this.vue.$router.push({ path: ripanoLinks[ripanoLinks.length - 1] });
          this.vue.$router.push({ path: ripanoLinks[0] });
        });
        return marker;
      },
    }).addTo(scans);
  }
  this.map.fitBounds(scans.getBounds());
};

VldmMap.prototype.addMarker = function (markerpositions, showMarker) {
  if (
    !markerpositions ||
    !markerpositions.data ||
    !markerpositions.data.length
  ) {
    return;
  }
  const photoMarkerCluster = L.markerClusterGroup({
    // spiderfyOnMaxZoom: false,
    // disableClusteringAtZoom: 18,
    iconCreateFunction: function (cluster) {
      return L.divIcon({
        iconSize: [40, 40],
        className:
          "marker-cluster-photo leaflet-marker-icon marker-cluster marker-cluster-small leaflet-zoom-animated leaflet-interactive",
        //     className: 'marker-cluster leaflet-marker-icon marker-cluster-small leaflet-zoom-animated leaflet-interactive',
        html: "<div><span>" + cluster.getChildCount() + "</span></div>",
      });
    },
  });
  const marker = L.markerClusterGroup({
    // spiderfyOnMaxZoom: false,
    // disableClusteringAtZoom: 18,
  });
  // marker.addTo(this.map);
  photoMarkerCluster.addTo(this.map);
  this.layerCtrl.addOverlay(marker, "Marker");
  this.layerCtrl.addOverlay(photoMarkerCluster, "Fotos");
  for (const scanpos of markerpositions.data) {
    L.geoJSON(scanpos, {
      pointToLayer: (feature, latlng) => {
        let iconUrl = require("../assets/mapicons/beenhere.svg");
        if (feature.properties.icon) {
          iconUrl = require(`../assets/mapicons/${feature.properties.icon}.svg`);
        }
        let markerPosIcon = L.icon({
          iconUrl: iconUrl,
          iconSize: [32, 32],
          iconAnchor: [10, 14],
          className: feature.properties.icon,
          popupAnchor: [0, 0],
        });
        const ripanoUrl = ripano.ripanoFullUrlFromMarkerProperties(
          feature.properties,
        );
        const popup = document.createElement("div");
        const ripanoLink = document.createElement("li");
        ripanoLink.innerHTML = `<a href="${ripanoUrl}" target="ripano">3D-Ansicht</a> (RiPANO)`;
        const vldmLink = document.createElement("li");
        vldmLink.innerHTML = `<span>Marker bearbeiten</span>`;
        vldmLink.classList.add("link");
        const linkList = document.createElement("ul");
        linkList.appendChild(vldmLink);
        linkList.appendChild(ripanoLink);
        popup.innerHTML = `<h4>${feature.properties.name}</h4>
        <p>${feature.properties.description || ""}</p>
        `;
        popup.appendChild(linkList);
        vldmLink.onclick = () => {
          this.vue.$router.push({ path: `/marker/edit/${feature._id}` });
        };
        const marker = L.marker(latlng, {
          icon: markerPosIcon,
        }).bindPopup(popup);
        return marker;
      },
      onEachFeature: (feature, layer) => {
        if (showMarker === feature._id) {
          layer.addTo(this.map);
          layer.openPopup();
        } else {
          if (feature.properties.icon === "camera-fill") {
            layer.addTo(photoMarkerCluster);
          } else {
            layer.addTo(marker);
          }
        }
      },
    });
  }
  // this.map.fitBounds(scans.getBounds());
};

export { VldmMap };
