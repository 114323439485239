<template>
  <b-embed type="iframe" :src="ripanoUrl" fluid class="vh-90"></b-embed>
</template>
<script>
import ripano from "../util/ripano";
export default {
  name: "RipanoIframe",
  data() {
    return {
      ripanoUrl: '',
    };
  },
  created() {
    console.log('created iframe component', this.$route.params);
    this.ripanoUrl = ripano.ripanoFullUrl(this.$route.params);
  }
};
</script>
<style scoped>
.vh-90 {
  max-height: 90vh;
}
</style>