var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"my-3 px-0"},[_c('b-row',[_c('b-col',[_c('h2',[_vm._v("Marker")])])],1),_c('b-row',[_c('b-col',{staticClass:"col-3"},[_c('h4',[_vm._v("Projekt")])]),_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.filterProjects},on:{"change":function($event){return _vm.changeProject($event)}},model:{value:(_vm.activeProject),callback:function ($$v) {_vm.activeProject=$$v},expression:"activeProject"}})],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Textsuche"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('b-col',[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"align":"fill","per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Pro Seite","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-for":"per-page-select"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.perPageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","bordered":"","current-page":_vm.currentPage,"total-rows":_vm.totalRows,"per-page":_vm.perPage,"fields":_vm.fields,"items":_vm.markers,"filter":_vm.filter,"filter-function":_vm.customFilterMarker},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(icon)",fn:function(data){return [(
              data.item.properties.icon === 'beenhere' ||
              data.item.properties.icon === '' ||
              !data.item.properties.icon
            )?_c('img',{attrs:{"src":_vm.BeenHere,"alt":"Marker"}}):(
              data.item.properties.icon === 'info-circle'
            )?_c('b-icon-info-circle',{attrs:{"icon":data.item.properties.icon}}):(
              data.item.properties.icon === 'plus-circle'
            )?_c('b-icon-plus-circle',{attrs:{"icon":data.item.properties.icon}}):(
              data.item.properties.icon === 'exclamation-circle'
            )?_c('b-icon-exclamation-circle',{attrs:{"icon":data.item.properties.icon}}):(
              data.item.properties.icon === 'camera-fill'
            )?_c('b-icon-camera-fill',{attrs:{"icon":data.item.properties.icon}}):_vm._e()]}},{key:"cell(name)",fn:function(data){return [(data.item.properties.link)?_c('a',{attrs:{"href":data.item.properties.link,"target":"_blank"}},[_c('b-icon-link'),_vm._v(" "+_vm._s(data.item.properties.name)+" ")],1):_c('span',[_vm._v(_vm._s(data.item.properties.name))])]}},{key:"cell(tags)",fn:function(data){return _vm._l((data.item.properties.tags),function(tag){return _c('b-badge',{key:tag,attrs:{"variant":"primary","pill":true}},[_vm._v(" "+_vm._s(tag)+" ")])})}},{key:"cell(edit)",fn:function(data){return [(_vm.$store.getters.hasRole('admin'))?_c('b-button',{on:{"click":function($event){return _vm.$router.push({
                name: 'EditMarker',
                params: { id: data.item._id },
              })}}},[_c('b-icon-pencil-square')],1):_vm._e()]}},{key:"cell(viewer)",fn:function(data){return [_c('b-button-group',[_c('b-button',{on:{"click":function($event){return _vm.showRipano(data.item)}}},[_c('b-icon-badge-3d')],1),_c('b-button',{staticClass:"ml-2",on:{"click":function($event){return _vm.$router.push({
                  name: 'MapProject',
                  params: {
                    id: data.item.properties.projectId,
                    marker: data.item._id,
                  },
                })}}},[_c('b-icon-map')],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }