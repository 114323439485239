<template>
  <b-container>
    <!-- <inline-map ref="map" v-bind:markers="markers"></inline-map> -->
    <b-row>
      <marker-table
        :allMarkers="markers"
        :allProjects="projects"
        :showTrashed="showTrashed"
        :preSelectedProject="activeProject"
        v-on:project-changed="projectChanged($event)"
        v-on:toggle-trashed="toggleShowTrashed()"
      ></marker-table>
    </b-row>
    <b-row>
      <b-button
        variant="primary"
        router-link
        :to="`/map/${this.activeProject}`"
        class="mt-3 mx-2"
      >
        Zum Projekt
      </b-button>
      <b-button
        variant="primary"
        href="/api/marker/csv"
        class="mt-3"
        v-if="hasAdmin"
      >
        CSV Export
      </b-button>
      <b-container v-if="!markers.length" class="mt-3">
        Noch keine Marker angelegt.
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
// import InlineMap from "./InlineMap";
import MarkerTable from "./MarkerTable";
import request from "../util/request";

export default {
  name: "MarkerList",
  data: function () {
    return {
      markers: [],
      activeProject: null,
      projects: [],
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      showTrashed: false,
      config: {
        map: {},
      },
      activeElement: "",
      // uploadFieldName: 'attach',
    };
  },
  computed: {
    hasAdmin() {
      return this.$store.getters.hasRole("admin");
    },
  },
  methods: {
    projectChanged(project) {
      this.activeProject = project;
      this.updateMarkers();
    },
    toggleShowTrashed() {
      this.showTrashed = !this.showTrashed;
      console.log("Now toggle: ", this.showTrashed);
      this.updateMarkers();
    },
    updateMarkers() {
      if (this.showTrashed) {
        request
          .get(`marker/project/${this.activeProject}?showDeleted=1`)
          .then((markers) => {
            this.markers = markers.data;
          });
      } else {
        request
          .get(`marker/project/${this.activeProject}`)
          .then((markers) => {
            console.log("markers: ", markers.data);
            if (markers.data.length) {
              this.markers = markers.data;
            } else {
              this.markers = [];
            }
          })
          .catch(() => {
            console.log("catch err");
            this.markers = [];
          });
      }
    },
  },
  created() {
    request.get("projects").then((p) => {
      console.log("projects: ", p);
      this.projects = p.data;
      console.log("route-params: ", this.$route.params.id);
      if (this.$route.params.id) {
        this.activeProject = this.$route.params.id;
      } else {
        this.activeProject = this.projects[0]._id;
      }
      this.updateMarkers();
    })
    .catch(err => {
      console.log('projects req err: ', err);
    });
  },
  components: {
    // InlineMap,
    MarkerTable,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.markerlist {
  text-align: left;
}
li {
  margin-bottom: 0.5em;
}
button {
  margin-left: 1em;
}
.label {
  background-color: beige;
  display: inline-block;
  min-width: 5em;
}
textarea {
  margin: 1em 0;
}
.active {
  background-color: lightgray;
}
</style>
