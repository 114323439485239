<template>
  <b-navbar class="navbar mt-4 navbar-light border-bottom bg-white-70">
    <b-container>
      <b-navbar-brand to="/">
        <img :src="vldlogo" class="d-none d-md-block" alt="VLDM" />
        <img :src="vldlogosmall" class="d-sm-block d-md-none" />
      </b-navbar-brand>
      <b-nav-text>{{ $store.getters.user?.name }} ({{ myGroups }})</b-nav-text>
      <b-navbar-nav class="ml-auto h5">
        <b-nav-item to="/map" v-if="this.$store.getters.isLoggedIn">
          <b-icon-map title="Übersichtskarte"></b-icon-map>
        </b-nav-item>
        <b-nav-item to="/" v-if="this.$store.getters.isLoggedIn">
          <b-icon-list-ol title="Liste aller Projekte"></b-icon-list-ol>
        </b-nav-item>
        <b-nav-item to="/marker" v-if="this.$store.getters.isLoggedIn">
          <b-icon-geo-alt title="List aller Marker"></b-icon-geo-alt>
        </b-nav-item>
        <b-nav-item to="/info">
          <b-icon-question-circle title="Hilfe"></b-icon-question-circle>
        </b-nav-item>
        <b-nav-item to="/import" v-if="hasAdmin">
          <b-icon-folder-plus title="Projekte importieren"></b-icon-folder-plus>
        </b-nav-item>
        <b-nav-item to="/users" v-if="hasAdmin">
          <b-icon-person title="User admin"></b-icon-person>
        </b-nav-item>
        <b-nav-item @click="login" v-if="!this.$store.getters.isLoggedIn">
          <b-icon-person title="Login"></b-icon-person>
        </b-nav-item>
        <b-nav-item @click="logout" v-if="this.$store.getters.isLoggedIn">
          <b-icon-box-arrow-right title="Logout"></b-icon-box-arrow-right>
        </b-nav-item>
      </b-navbar-nav>
    </b-container>
  </b-navbar>
</template>
<script>
import vldlogo from "../assets/vldlogo2.svg";
import vldlogosmall from "../assets/vldlogo_small.svg";
import {
  BIconMap,
  BIconFolderPlus,
  BIconGeoAlt,
  BIconQuestionCircle,
  BIconPerson,
  BIconBoxArrowRight,
  BIconListOl,
} from "bootstrap-vue";
export default {
  name: "NavbarComponent",
  components: {
    BIconMap,
    BIconFolderPlus,
    BIconGeoAlt,
    BIconQuestionCircle,
    BIconPerson,
    BIconBoxArrowRight,
    BIconListOl,
  },
  data: function () {
    return {
      vldlogo,
      vldlogosmall,
    };
  },
  computed: {
    hasAdmin() {
      return this.$store.getters.hasRole("admin");
    },
    myGroups() {
      const groups = this.$store.getters.user?.roles || [];
      return groups.join(",");
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        window.location = '/api/auth/signout';
        // this.$router.push("/login");
      });
    },
    login: function () {
      if (this.$router.currentRoute.path !== "/login") {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style lang="scss">
nav {
  img.logo {
    max-height: 50px;
  }
}
.color-primary {
  // color: $theme-color("primary");
  color: var(--primary);
}
.navbar-light .navbar-nav .nav-link {
  &.router-link-exact-active {
    color: var(--primary);
  }
}
</style>