<template>
  <b-container>
    <b-card
      class="mt-3"
      v-for="projectFile of projectFiles"
      v-bind:key="projectFile.file"
      :title="projectFile.name"
    >
      <b-card-text>
        <div v-if="projectFile.imported">
          <span variant="success" class="mr-3">Importiert</span>
          <b-icon-check variant="success"></b-icon-check>
          <div>{{ projectFile.imported?.scanPosCount }} Scanpositionen</div>
          <div>
            <router-link :to="`/map/${projectFile.imported.project._id}`"
              >zur Karte</router-link
            >
          </div>
        </div>
        <div v-if="projectFile.dbProject">
          <div>
            Projekt bereits importiert:
            {{ formatDate(projectFile.dbProject.createdAt) }}.
          </div>
        </div>
      </b-card-text>
      <b-button
        @click="importProject(projectFile.file)"
        v-if="!projectFile.dbProject"
      >
        <b-icon-folder-plus></b-icon-folder-plus> Import
      </b-button>
    </b-card>
    <b-alert
      v-model="showError"
      style="z-index: 2000"
      variant="danger"
      dismissible
    >
      <p>Das Projekt konnte nicht importiert werden.</p>
      <pre>{{ errorMessage }}</pre>
      <p>
        Für eine 
        <b-link to="/projections">
        Liste der gültigen Projektionsnamen
        </b-link>
        im RiPano Projekt klicken
        Sie
        <b-link to="/projections">hier</b-link>.
      </p>
    </b-alert>
  </b-container>
</template>
<script>
import request from "../util/request";
import { BIconFolderPlus, BIconCheck } from "bootstrap-vue";

export default {
  components: {
    BIconFolderPlus,
    BIconCheck,
  },
  data() {
    return {
      projectFiles: [],
      errorMessage: "",
      showError: false,
    };
  },
  mounted: function () {
    this.getImportProjectList();
  },
  methods: {
    formatDate(dateString) {
      console.log("format: ", dateString);
      const d = new Date(dateString);
      return d.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
    getImportProjectList: function () {
      request.get("projects/import/list").then((projects) => {
        console.log("projectFiles: ", projects.data);
        for (let project of projects.data) {
          this.projectFiles.push({
            ...project,
            name: project.file.replace(
              /^\/ripano\/Projects\/(.*)\/project\.json$/,
              "$1"
            ),
          });
        }
      });
    },
    importProject: function (file) {
      this.showError = false;
      request
        .post("projects/import", { file })
        .then((importRes) => {
          console.log("import status: ", importRes.data);
          this.projectFiles = this.projectFiles.map((p) => {
            if (p.file === file) {
              p.imported = importRes.data;
              console.log("Project update: ", p);
            }
            return p;
          });
        })
        .catch((err) => {
          console.log("import error: ", err);
          console.log("error: ", err.response.data.errors[0]);
          if (err.response.data?.errors?.[0].code === 11000) {
            this.$bvToast.toast(
              "Ein Projekt mit diesem Namen existiert bereits. Bitte löschen Sie das Projekt und versuchen Sie es erneut.",
              {
                title: "Fehler",
                variant: "danger",
                solid: true,
                // autoHideDelay: 5000,
              }
            );
          } else {
            this.errorMessage = err.response.data?.errors?.[0];
            this.showError = true;
          }
        });
    },
  },
};
</script>
