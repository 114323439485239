<template>
  <b-container>
    <b-card title="Passwort ändern" class="mt-3">
      <b-card-text>
        <p>Nach erfolgreicher Änderung werden Sie auf die Login-Seite weitergeleitet.</p>
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Aktuelles Passwort:"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              ref="inputGroup1"
              v-model="currentPassword"
              type="password"
              placeholder="aktuelles Passwort"
              required
              minlength="5"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Neues Passwort:"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="newPassword1"
              type="password"
              placeholder="Neues Passwort"
              required
              minlength="7"
              maxlength="20"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Neues Passwort wiederholen:"
            label-for="input-3"
          >
            <b-form-input
              id="input-3"
              v-model="newPassword2"
              type="password"
              placeholder="neues Passwort wiederholen"
              :state="newPass2Valid"
              minlength="7"
              maxlength="20"
              required
            ></b-form-input>
            <b-form-invalid-feedback :state="newPass2Valid">
              Passwörter stimmen nicht überein
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button type="submit" variant="primary">Ändern</b-button>
        </b-form>
    <b-alert
      class="mt-3"
      :show="!!errors.length"
      style="z-index: 2000"
      variant="danger"
      dismissible
    >
      <p>Fehler</p>
      <p v-for="(error,i) in errors" :key="i">{{ error.message }}</p>
    </b-alert>
      </b-card-text>
    </b-card>
  </b-container>
</template>
<script>
import request from '../util/request';

export default {
  name: "ChangePassword",
  data() {
    return {
      errors: [],
      currentPassword: "",
      newPassword1: "",
      newPassword2: "",
      newPass2Valid: null,
    };
  },
  mounted() {
    this.$refs.inputGroup1.$el.focus();
  },
  methods: {
    onSubmit() {
      if (this.newPassword1 !== this.newPassword2) {
        this.newPass2Valid = false;
        return;
      }
      this.newPass2Valid = null;
      request.post('users/changePassword', {
        currentPassword: this.currentPassword,
        newPassword1: this.newPassword1,
        newPassword2: this.newPassword2,
      }).then(() => {
        this.$router.push('/login');
      }).catch(err => {
        console.log('error', err);
        this.errors = err.response.data.errors;
      });
    },
  }
};
</script>