<template>
  <b-container class="mt-3" fluid>
    <div ref="pano" id="pano"></div>
    <b-button @click="showRiPano()">RIPANO</b-button>
  </b-container>
</template>
<script>
import marzipano from "marzipano";
import ripano from "../util/ripano";
export default {
  name: "PanoComponent",
  data() {
    return {};
  },
  mounted() {
    const viewer = new marzipano.Viewer(this.$refs.pano);
    var source = marzipano.ImageUrlSource.fromString(ripano.panoUrl(this.$route.params));
    const geometry = new marzipano.CubeGeometry([
      { tileSize: 1910, size: 1910 }
    ]);
    const limiter = marzipano.RectilinearView.limit.traditional(
      4096,
      (100 * Math.PI) / 180
    );
    const view = new marzipano.RectilinearView(null, limiter);
    const scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true
    });
    // Display scene.
    scene.switchTo();
  },
  methods: {
    ripanoUrl(ele) {
      return ripano.ripanoUrl(ele)
    },
    showRiPano() {
      const params = {
        p: this.$route.params.ripanoProject,
        sp: this.$route.params.scanPosition,
        vt: this.$route.params.title,
        phi: 60,
        theta: 0,
        fov: 90
      };
      this.$router.push({ name: 'Ripano', params: params });
    }
  }
};
</script>
<style scoped>
#pano {
  position: relative;
  width: 100%;
  height: 80vh;
}
</style>