<template>
  <footer class="bg-primary bd-footer list-unstyled my-5 text-white text-sm-left">
    <b-container>
      <b-row class="py-2">
        <b-col>
          <li class="title-footer">vldm - very large data map</li>
          <li>EKG BAUKULTUR Ziviltechniker &amp; <a href='https://webman.at/' target="_new">webman.at</a></li>
          <!-- <li>Webapplikation UAW - Werke, Primärtechnik</li>
          <li>A-1220 Wien, Wagramer Straße 19 (IZD-Tower)</li> -->
          <li>Alpenmilchzentrale Viktorgasse 22, 1040 Wien</li>
          <li>
            <a href="mailto:info@ekg-baukultur.com">email</a>
          </li>
          <li>
            <a href="http://www.ekg-baukultur.com" target="blank">© EKG BAUKULTUR ZT GmbH 2020</a>
          </li>
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>
export default {
  name: "EkgFooter"
};
</script>
<style lang="scss" scoped>
footer {
  .title-footer {
    font-size: medium;
  }
  font-size: small;
  a {
    color: var(--secondary);
  }
}
</style>