<template>
  <div class="projectList">
    <h4>Projekte</h4>
    <inline-map ref="map" :initialView="$route.params.initialView"></inline-map>
    <b-input id="filterTable" v-model="pFilter" placeholder="Filter eingeben" class="my-2" />
    <b-table-simple class="table table-sm table-striped table-bordered table-hover">
      <thead class="thead-light">
        <tr class="text-center">
          <th>SKS</th>
          <th>WNR</th>
          <th>Name</th>
          <th>TLS</th>
          <th>Virt.Anl.</th>
          <th>ALS</th>
          <th>Luftbild</th>
          <th>2D-CAD</th>
          <th>sonstige</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in filteredProjects" :key="p._id" :id="p._id">
          <td>{{ p.SKS }}</td>
          <td>{{ p.WNR }}</td>
          <td>
            <b-btn text @click="moveMap(p)" variant="link">{{ p.NAME}}</b-btn>
          </td>
          <td class="text-center">
            <div v-if="p.ripanoProjekt">
              <a
                v-bind:href="`https://vldm.at/ripano/?p=${p.ripanoProjekt}`"
                target="_blank"
              >
                <project-status-marker v-bind:val="p.tlsScal"></project-status-marker>
              </a>
            </div>
            <div v-else>
              <project-status-marker v-bind:val="p.tlsScal"></project-status-marker>
            </div>
          </td>
          <td>
            <project-status-marker v-bind:val="p.TLS"></project-status-marker>
          </td>
          <td class="text-center">
            <project-status-marker v-bind:val="p.alsScan"></project-status-marker>
          </td>
          <td>
            <project-status-marker v-bind:val="p.ortho"></project-status-marker>
          </td>
          <td>
            <project-status-marker v-bind:val="p['2dCad']"></project-status-marker>
          </td>
          <td>{{ p.sonstiges }}</td>
          <!-- <td ><project-status-marker v-bind:val="p.TLS"></project-status-marker></td> -->
        </tr>
      </tbody>
    </b-table-simple>
  </div>
</template>

<script>
import request from "../util/request";
import ProjectStatusMarker from "./ProjectStatusMarker";
import InlineMap from "./InlineMap";
// TODO: Suchfeld oberhalb der Liste, mit Einschränkung beim Tippen
export default {
  name: "ProjectList",
  data: function() {
    return {
      projects: [],
      pFilter: null,
    };
  },
  methods: {
    toggleStatus(p) {
      console.log("change project status: ", p);
    },
    moveMap(p) {
      console.log("move map to ", p.loc);
      window.scrollTo(0, 0);
      this.$refs.map.setView(p.loc);
    }
  },
  created: function() {
    console.log("create plist: ", this.$route.params.initialView);
    request.get("projects").then(
      ps => {
        console.log("Projects: ", ps);
        this.projects = ps.data;
      },
      err => {
        console.log("Error loading projects: ", err);
      }
    );
  },
  computed: {
    filteredProjects() {
      if (! this.projects.length) {
        return [];
      }
      return this.projects.filter(project => {
        let props = [project.BUNDESLAND, project.NAME, project.SKS, project.WNR];
        return props.some(prop => !this.pFilter || ((typeof prop === 'string') ? prop.toLowerCase().includes(this.pFilter.toLowerCase()) : prop.toString(10).toLowerCase().includes(this.pFilter.toLowerCase())))
      });
    }
  },
  components: {
    ProjectStatusMarker,
    InlineMap
  }
};
</script>
<style scoped>
.geolink {
  text-decoration: underline;
  cursor: pointer;
}
</style>