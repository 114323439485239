<template>
  <b-container class="mt-3">
    <b-row>
      <h4>
        Marker bearbeiten: {{ markerprops.name }} ({{
          markerprops.ripanoProject
        }})
      </h4>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="2">
        <label for="mname">Name:</label>
      </b-col>
      <b-col>
        <b-form-input
          id="mname"
          v-model="markerprops.name"
          placeholder="Marker Name"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="2">
        <label for="mdesc">Beschreibung</label>
      </b-col>
      <b-col>
        <b-form-textarea
          input-id="mdesc"
          v-model="markerprops.description"
          rows="3"
          placeholder="Beschreibung"
        ></b-form-textarea>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="2">
        <label for="mlink" >Link
          <b-icon-info-circle v-b-tooltip title="Link zu Ihren Cloud-/Jira-/Ticket-Systemen"></b-icon-info-circle>
        </label>
      </b-col>
      <b-col>
        <b-form-input
          input-id="mlink"
          v-model="markerprops.link"
          placeholder="https://.."
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="2">
        <label for="micon">Icon</label>
      </b-col>
      <b-col>
        <b-button :variant="(currentIcon === 'beenhere') ? 'primary' : 'outline-primary'" @click="setIcon('beenhere')" class="mx-2">
          <img :src="BeenHere" alt="Marker" />
        </b-button>
        <b-button :variant="(currentIcon === 'info-circle') ? 'primary' : 'outline-primary'" @click="setIcon('info-circle')" class="mx-2">
          <b-icon-info-circle></b-icon-info-circle>
        </b-button>
        <b-button :variant="(currentIcon === 'plus-circle') ? 'primary' : 'outline-primary'" @click="setIcon('plus-circle')" class="mx-2">
          <b-icon-plus-circle></b-icon-plus-circle>
        </b-button>
        <b-button :variant="(currentIcon === 'exclamation-circle') ? 'primary' : 'outline-primary'" @click="setIcon('exclamation-circle')" class="mx-2">
          <b-icon-exclamation-circle></b-icon-exclamation-circle>
        </b-button>
        <b-button :variant="(currentIcon === 'camera-fill') ? 'primary' : 'outline-primary'" @click="setIcon('camera-fill')" class="mx-2">
          <b-icon-camera-fill></b-icon-camera-fill>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="2">
        <label for="mtags">Schlagworte</label>
      </b-col>
      <b-col>
        <b-form-tags
          input-id="mtags"
          tag-variant="primary"
          v-model="markerprops.tags"
          placeholder="Schlagworte mit Enter trennen"
        ></b-form-tags>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="success" class="mt-3" @click="saveMarker()">
          Speichern
        </b-button>
        <b-button variant="danger" class="mt-3 mx-2" @click="deleteMarker();">
          Löschen
        </b-button>
      </b-col>
      <b-col >
        <b-button
          variant="info"
          class="mt-3 ml-2 float-right"
          :to="`/marker/${markerprops.projectId}`"
        >
          Zurück zur Liste
        </b-button>
        <b-button variant="warning" class="mt-3 mx-2 float-right" :href="rpUrl" target="ripano">
          RiPANO (3D-Ansicht)
        </b-button>
        <b-button variant="secondary" class="mt-3 mx-2 float-right" :to="`/map/${markerprops.projectId}/${id}`">
          auf Karte anzeigen
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import request from "../util/request";
import ripano from "../util/ripano";
import BeenHere from "../assets/beenhere.svg";
import { BIconInfoCircle, BIconPlusCircle, BIconExclamationCircle, BIconCameraFill } from "bootstrap-vue";

export default {
  name: "EditMarker",
  components: {
    BIconInfoCircle,
    BIconPlusCircle,
    BIconExclamationCircle,
    BIconCameraFill,
  },
  data: function () {
    return {
      BeenHere,
      markerprops: {},
      currentIcon: '',
      rpUrl: "",
      id: "",
    };
  },
  created() {
    console.log("created edit marker component", this.$route.params);
    this.id = this.$route.params.id;
    this.getMarker();
  },
  methods: {
    setIcon(icon) {
      console.log('use icon', icon);
      this.markerprops.icon = icon;
      this.currentIcon = icon;
      console.log(this.markerprops);
    },
    getMarker() {
      request.get(`marker/${this.id}`).then((marker) => {
        console.log("got marker", marker);
        this.markerprops = marker.data.properties;
        this.rpUrl = ripano.ripanoFullUrlFromMarkerProperties(this.markerprops);
        this.currentIcon = this.markerprops.icon || 'beenhere';
      });
    },
    saveMarker() {
      request
        .patch(`marker/${this.id}`, { properties: this.markerprops })
        .then((marker) => {
          console.log("Done with marker: ", marker);
          this.$router.push(`/marker/${this.markerprops.projectId}`);
        });
    },
    async deleteMarker() {
      const res = await this.$bvModal.msgBoxConfirm(
        `Marker wirklich löschen?`,
        {
          title: this.markerprops.name,
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Löschen",
          cancelTitle: "Abbruch",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        }
      );
      if (res) {
        request.delete(`marker/${this.id}`).then((marker) => {
          console.log("Done with marker: ", marker);
          this.$router.push(`/marker/${this.markerprops.projectId}`);
        });
      }
    },
  },
};
</script>