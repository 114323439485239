<template>
  <b-container class="mt-3 p-0" fluid>
    <div id="map"></div>
  </b-container>
</template>
<script>
// import * as L from "leaflet";
import { VldmMap } from "../map";
import request from "../util/request";
export default {
  name: "MapProject",
  data: function () {
    return {
      map: {},
    };
  },
  methods: {
    initMap() {
      this.map = new VldmMap(document.getElementById("map"), {
        Vue: this,
      });
      console.log("MapProj map: ", this.map);
    },
  },
  created() {
    console.log("P: ", this.$route.params.id);
  },
  mounted() {
    this.initMap();
    request.get(`scanpositions/${this.$route.params.id}`).then((scanpos) => {
      this.map.addScanPos(scanpos);
    });
    request.get(`marker/project/${this.$route.params.id}`).then((markerpos) => {
      this.map.addMarker(markerpos, this.$route.params.marker);
    });
  },
};
</script>
<style>
#map {
  margin: auto;
  width: 100%;
  height: 80vh;
  /* background: lightblue; */
}
.leaflet-control {
  text-align: left;
}
.leaflet-container .leaflet-marker-pane img.leaflet-marker-icon.camera {
  background-color: lightcoral;
  padding: 0.3em;
}
.marker-cluster-photo {
  background-color: var(--primary-opacity);
}
.marker-cluster-photo div {
  background-color: var(--primary-opacity);
}

.apg-logo img {
  width: 200px;
}
.leaflet-top.leaflet-left .leaflet-control {
  clear: none;
}
</style>
