import * as L from "leaflet";
import "leaflet-providers";
import * as protomaps from "protomaps-leaflet";

const providerLayers = function () {
  let PAINT_RULES = [
    {
      dataLayer: "pb_lines",
      filter: (z, f) => {
        if (f.props?.power && f.props?.operator === "Austrian Power Grid AG") {
          return true;
        }
      },
      symbolizer: new protomaps.LineSymbolizer({
        color: "#e6392e",
        strokeWidth: 4,
      }),
    },
    {
      dataLayer: "pb_lines",
      filter: (z, f) => {
        return (f.props.admin_level || 9999) < 5;
      },
      symbolizer: new protomaps.LineSymbolizer({
        color: "#999",
        strokeWidth: 4,
      }),
    },
  ];
  console.log("protomaps: ", protomaps.leafletLayer, PAINT_RULES);
  const pmLayer = protomaps.leafletLayer({
    url: "pb.pmtiles",
    paint_rules: PAINT_RULES,
    label_rules: [],
  });
  return {
    "Geoland Basemap": L.tileLayer.provider("BasemapAT.highdpi", {
      baseLayer: true,
    }),
    "Geoland Ortho": L.tileLayer.provider("BasemapAT.orthofoto", {
      baseLayer: true,
      maxZoom: 19,
    }),
    OpenStreetMap: L.tileLayer.provider("OpenStreetMap.Mapnik", {
      baseLayer: true,
    }),
    Simple: pmLayer,
  };
};

const layerControl = function () {
  return L.control.layers();
};

export { layerControl, providerLayers };
