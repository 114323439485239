<template>
  <b-container>
    <b-button
      v-if="$store.getters.localAuth"
      block
      class="mt-4"
      size="sm"
      @click="addUserDialog"
      variant="outline-primary"
      >Benutzer hinzufügen</b-button
    >
    <b-button
      v-else
      block
      class="mt-4"
      size="sm"
      @click="addUserDialog"
      variant="outline-primary"
      >Neuer Benutzer für Projekte</b-button
    >
    <b-table
      striped
      hover
      bordered
      class="mt-3"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      :fields="fields"
      :items="users"
      :filter="filter"
      :filter-function="customFilterUser"
      @filtered="onFiltered">
      <template v-slot:cell(email)="data">
        {{ data.item.email }}
        <b-button
          v-if="$store.getters.localAuth"
          block
          size="sm"
          @click="resetPassword(data.item)"
          variant="outline-primary"
          >Neues Passwort</b-button
        >
        <b-button
          v-if="!data.item.geojsonToken"
          block
          size="sm"
          @click="addGeojsonTokenDialog(data.item)"
          variant="outline-primary"
          >Neues Geojson token</b-button
        >
        <b-button
          v-else
          block
          size="sm"
          @click="delGeojsonToken(data.item)"
          variant="warning"
          >Geojson token löschen</b-button
        >
        <b-button
          block
          size="sm"
          @click="delUser(data.item)"
          variant="danger"
          >Benutzer löschen</b-button
        >
      </template>
      <template v-slot:cell(roles)="data">
        <b-form-select
          v-model="data.item.roles"
          :options="allRoles"
          multiple
          :select-size="3"></b-form-select>
      </template>
      <template v-slot:cell(projects)="data">
        <b-icon-plus-circle @click="addProject(data.item)"></b-icon-plus-circle>
        <b-list-group>
          <b-list-group-item
            v-for="p of data.item.projects"
            v-bind:key="p.projectName"
            :variant="isExpired(p.expiresAt) ? 'warning' : ''">
            {{ p.projectName }}
            <span v-if="p.expiresAt">(bis {{ dateFormat(p.expiresAt) }})</span>
            <b-icon-x-circle
              @click="removeProject(p, data.item)"
              style="float: right"></b-icon-x-circle>
          </b-list-group-item>
        </b-list-group>
      </template>
    </b-table>
    <b-modal
      ref="project-admin"
      @ok="saveProjectAdmin"
      @cancel="cancelProjectAdmin">
      <b-form-select
        :options="allProjectsFiltered"
        v-model="selectedProjectAdmin.projects"
        multiple
        :select-size="3"></b-form-select>
      <b-form-datepicker
        v-model="selectedProjectAdmin.date"
        class="mb-2"></b-form-datepicker>
    </b-modal>
    <b-modal
      ref="add-user"
      @ok="addUser"
      @cancel="cancelAddUser"
      title="Benutzer hinzufügen">
      <label for="new-user-email">Email</label>
      <b-form-input
        id="new-user-email"
        v-model="newUser.email"
        aria-placeholder="Email"
        placeholder="email@example.com"
        class="mb-2"></b-form-input>
      <div v-if="$store.getters.localAuth">
        <label for="new-user-name">Name</label>
        <b-form-input
          id="new-user-name"
          v-model="newUser.name"
          aria-placeholder="Vorname Nachname"
          placeholder="Vorname Nachname"
          class="mb-2"></b-form-input>
        <label for="new-user-roles">Rollen</label>
        <b-form-select
          id="new-user-roles"
          :options="allRoles"
          v-model="newUser.roles"
          multiple
          :select-size="3"></b-form-select>
      </div>
    </b-modal>
    <b-modal
      ref="geojson-token-date"
      title="GeoJSON token"
      @ok="saveGeojsonToken">
      <label for="geojson-token-valid">Gültig bis</label>
      <b-form-datepicker
        id="geojson-token-valid"
        v-model="geojsonTokenValid"
        class="mb-2"></b-form-datepicker>
    </b-modal>
    <b-modal
      ref="geojson-token"
      title="GeoJSON token"
      ok-only
      @ok="
        geojsonToken = null;
        geojsonTokenValid = null;
      ">
      <p>
        Schicken Sie dieses Token im HTTP-Header <code>authorization</code> als
        <code>Bearer</code> mit.
      </p>
      <pre>
curl -H "Authorization: Bearer {{
          geojsonToken
        }}" https://[your-server.com]/api/marker/geojson
      </pre>
      <textarea v-model="geojsonToken" rows="5" cols="50"></textarea>
    </b-modal>
  </b-container>
</template>
<script>
import request from "../util/request";
import moment from "moment";
import { BIconPlusCircle, BIconXCircle } from "bootstrap-vue";

export default {
  components: {
    BIconPlusCircle,
    BIconXCircle,
  },
  data: function () {
    return {
      users: [],
      selectedProjectAdmin: {
        projects: [],
        date: null,
      },
      selectedUser: {},
      newUser: {
        email: "",
        name: "",
        roles: [],
      },
      geojsonToken: "",
      geojsonTokenValid: null,
      allRoles: ["admin", "user", "guest"],
      allProjects: [],
      allProjectsFiltered: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 100,
      fields: [
        { key: "email", label: "Name", sortable: true },
        { key: "projects", label: "Projects", sortable: false },
      ],
      filter: null,
    };
  },
  created() {
    request.get("users").then((users) => {
      this.users = users.data;
    });
    request.get("projects").then((projects) => {
      this.allProjects = projects.data.map((p) => p.ripanoProject);
    });
    console.log("SYSTEM: ", this.$store.getters.system);
    // fixme: this is not working as expected
    if (this.$store.getters.localAuth) {
      this.fields.push({ key: "roles", label: "Roles", sortable: false });
    }
  },
  methods: {
    isExpired(d) {
      if (d === null || d === "") return false;
      const expD = new Date(d);
      return expD < new Date();
    },
    dateFormat(d) {
      if (d === null || d === "") return "~";
      return moment(d).format("DD.MM.YYYY");
    },
    patchUser(u) {
      console.log("patch: ", u);
      // FIXME
    },
    resetPassword(u) {
      console.log("reset password: ", u);
      request.post(`users/${u._id}/password/reset`).then((res) => {
        console.log("reset password: ", res);
        this.$bvModal.msgBoxOk(
          `Neues Passwort: ${res.data?.newPassword?.pass}`,
          {
            title: "Neues Passwort",
          },
        );
      });
    },
    addGeojsonTokenDialog(u) {
      console.log("reset geojson token: ", u);
      this.selectedUser = u;
      this.$refs["geojson-token-date"].show();
    },
    async saveGeojsonToken() {
      const resp = await request.post(
        `users/${this.selectedUser._id}/geojson/generate`,
        {
          valid: this.geojsonTokenValid,
        },
      );
      this.geojsonToken = resp.data.token;
      this.selectedUser.geojsonToken = this.geojsonToken;
      this.$refs["geojson-token"].show();
    },
    delGeojsonToken(u) {
      console.log("delete geojson token: ", u);
      request.post(`users/${u._id}/geojson/delete`).then((res) => {
        console.log("delete geojson token: ", res);
        u.geojsonToken = null;
      });
    },
    delUser(u) {
      confirm(`Benutzer ${u.email} wirklich löschen?`) &&
        request.delete(`users/${u._id}`).then((res) => {
          console.log("delete user: ", res);
          this.users = this.users.filter((user) => user._id !== u._id);
        });
    },
    addUserDialog() {
      console.log("add user");
      this.$refs["add-user"].show();
    },
    addUser() {
      console.log("add user");
      request.post("users", this.newUser).then((res) => {
        console.log("add user: ", res);
        this.users.push(res.data);
        this.newUser = {
          email: "",
          name: "",
          roles: [],
        };
        this.$refs["add-user"].hide();
      });
    },
    cancelAddUser() {
      this.newUser = {
        email: "",
        name: "",
        roles: [],
      };
      this.$refs["add-user"].hide();
    },
    addProject(u) {
      console.log("add project: ", u);
      this.selectedProjectAdmin = {
        user: u,
        projects: u.projects,
        date: null,
      };
      this.allProjectsFiltered = this.allProjects.filter(
        (p) => !u.projects?.some((up) => up.projectName === p),
      );
      this.$refs["project-admin"].show();
    },
    removeProject(p, u) {
      console.log("remove project: ", p, u);
      request
        .post(`users/${u._id}/projects/delete`, {
          project: p.projectName,
          expiresAt: p.expiresAt,
        })
        .then(() => {
          u.projects = u.projects.filter(
            (up) => up.projectName !== p.projectName,
          );
        });
    },
    cancelProjectAdmin() {
      this.$refs["project-admin"].hide();
    },
    async saveProjectAdmin() {
      console.log("save project admin: ", this.selectedProjectAdmin);
      await request.patch(
        `users/${this.selectedProjectAdmin.user._id}/projects`,
        {
          projects: this.selectedProjectAdmin.projects,
          date: this.selectedProjectAdmin.date,
        },
      );
      request.get("users").then((users) => {
        this.users = users.data;
      });
      this.$refs["project-admin"].hide();
    },
    onFiltered(filteredItems) {
      console.log("ONFILTERED: ", filteredItems);
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    customFilterUser(row, filter) {
      return (
        row.properties.name.toLowerCase().includes(filter.toLowerCase()) ||
        row.properties?.tags?.some((tag) => {
          return tag.toLowerCase().includes(filter.toLowerCase());
        }) ||
        row.properties.description
          ?.toLowerCase()
          .includes(filter.toLowerCase()) ||
        row.properties.modifiedBy?.email
          ?.toLowerCase()
          .includes(filter.toLowerCase())
      );
    },
  },
};
</script>
