<template>
  <div>
    <b-sidebar id="sidebar-1" title="Projekte" shadow>
      <b-form-group class="p-3">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="projectSearch"
            type="search"
            placeholder="Suchen"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!projectSearch" @click="projectSearch = ''"
              >Löschen</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div class="px-3 py-2">
        <b-list-group>
          <b-list-group-item
            v-for="project in filteredProjects"
            :key="project._id"
            @click="map.openPopup(project._id)"
            class="d-flex align-items-center"
            style="cursor: pointer">
            <b-button
              v-if="hasAdmin"
              @click.stop="openProject(project)"
              size="sm"
              variant="outline-secondary">
              <b-icon-pencil></b-icon-pencil>
            </b-button>
            <span class="ml-2">
              {{ project.name }}
            </span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-sidebar>
    <b-container class="mt-3">
      <b-button v-b-toggle.sidebar-1 variant="outline-primary">
        <b-icon-list></b-icon-list>
      </b-button>
      <div id="map" class="mt-3"></div>
    </b-container>
    <project-edit-dialog
      @project-updated="updateProject"
      :project="selectedProject"></project-edit-dialog>
  </div>
</template>
<script>
import * as L from "leaflet";
// import "leaflet.locatecontrol";
// import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import { VldmMap } from "../map";
import ProjectEditDialog from "./ProjectEditDialog.vue";
import { BIconList, BIconPencil } from "bootstrap-vue";
import request from "../util/request";
import debounce from "debounce";
export default {
  name: "MapComponent",
  components: {
    BIconList,
    BIconPencil,
    ProjectEditDialog,
  },
  data: function () {
    return {
      svgMarker: "",
      markers: [],
      projects: [],
      filteredProjects: [],
      projectSearch: null,
      selectedProject: {},
      map: {},
      markerLayer: {},
    };
  },
  computed: {
    hasAdmin() {
      return this.$store.getters.hasRole("admin");
    },
  },
  watch: {
    projectSearch: function () {
      console.log("change search: ", this.projectSearch);
      const re = new RegExp(this.projectSearch || "", "i");
      this.filteredProjects = this.projects.filter((p) => p.name?.match(re));
      debounce(() => {
        this.map.addProjects(this.filteredProjects);
      }, 500)();
    },
  },
  methods: {
    updateProject(p) {
      console.log("update project: ", p);
      this.selectedProject = {};
      this.map.updateProject(p);
    },
    openProject(p) {
      console.log("open project: ", p);
      this.selectedProject = p;
      this.$bvModal.show("edit-project");
    },
    initMap() {
      this.markerLayer = L.featureGroup();
      this.map = new VldmMap(document.getElementById("map"), {
        Vue: this,
        baseLayer: "Simple",
      });
      // this.map.setupMap(this.$appConfig)
      // L.control.locate().addTo(this.map.map);
      // this.markerLayer.addTo(this.map);
      // layerCtrl.addOverlay(this.markerLayer, "Marker");
    },
    makeMarker(feature, latlng) {
      const myMarker = L.marker(latlng, {
        icon: L.icon({
          iconUrl: require("../assets/beenhere.png"),
          iconSize: [25, 25], // size of the icon
          iconAnchor: [10, 14], // point of the icon which will correspond to marker's location
          popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
        }),
      });

      let pop = `<h3>${feature.properties.title}</h3>
      <p>${feature.properties.description || ""}</p>
      `;
      if (feature.properties.attachments) {
        for (const attach of feature.properties.attachments) {
          if (attach.thumbnail) {
            pop += ` <img style='width:200px; height:200px' src="data:${attach.mime};base64,${attach.thumbnail}" /> `;
          }
        }
      }
      pop += `<footer><a target="blank" href="${feature.properties.url}">Ripano</a></footer>`;
      myMarker.bindPopup(pop);
      return myMarker;
    },
  },
  created() {
    this.svgMarker = require("../assets/beenhere.svg");
    // console.log('m: ', this.svgMarker);
    request.get("marker").then((markers) => {
      this.markers = markers.data;
      // console.log('markers is set to : ', this.markers);
      if (this.markers && this.markers.length) {
        L.geoJson(this.markers, {
          pointToLayer: this.makeMarker,
        }).addTo(this.markerLayer);
        // this.map.fitBounds(this.markerLayer.getBounds());
      }
    });
  },
  mounted() {
    // console.log('map mounted, config is ', this.$appConfig);
    this.initMap();
    request
      .get("projects")
      .then((projects) => {
        // console.log("all projects for you: ", projects);
        this.projects = projects.data;
        this.filteredProjects = this.projects;
        this.map.addProjects(this.filteredProjects);
      })
      .catch((err) => {
        console.log("projects-err: ", err);
      });
  },
};
</script>

<style lang="scss">
@import "../../node_modules/leaflet/dist/leaflet.css";
#map {
  margin: auto;
  width: 100%;
  height: 80vh;
  // background: lightblue;
}
.leaflet-control {
  text-align: left;
}
.link > span {
  color: var(--primary);
  cursor: pointer;
}
</style>
