import axios from 'axios'
// import router from '../router'
// import store from '../store'
import Vue from 'vue'

const service = {
  baseUrl: '/api/'
}

axios.interceptors.response.use(function(response) {
  // console.log('axios intercept . store is', store, 'vue');
  return response;
}, function(err) {
  console.log('RET in axios interc: ', err);
  // console.log('CFG in axios interc: ', err.config, err.response?.status, err.status);
  if (err && (err.response?.status === 401 || err.response?.status === 403)) {
    console.log('INTERCEPT error ', err.response.status, Vue.router.currentRoute);
    if (Vue.router.currentRoute.path !== '/login') {
      Vue.router.push('/login');
    } 
    return Promise.reject(err);
  } else if (typeof err.response === "undefined") {  // preflight OPTIONS request with 401 cannot return a response
    console.log('INTERCEPT error undefined');
    return Vue.router.push('/login');
  } else {
    console.log('INTERCEPT error ', err);
    return Promise.reject(err);
  }
});

export default {
  get(url) {
    return axios.get(service.baseUrl + url);
  },
  delete(url) {
    return axios.delete(service.baseUrl + url)
  },
  post(url, body, opts) {
    return axios.post(service.baseUrl + url, body, opts);
  },
  put(url, body) {
    return axios.put(service.baseUrl + url, body);
  },
  patch(url, body) {
    return axios.patch(service.baseUrl + url, body);
  },
  upload(formData) {
    const url = `${service.baseUrl}/upload`;
    return axios.post(url, formData)
  },
  setToken(token) {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
  },
  removeToken() {
    delete axios.defaults.headers.common['Authorization']
  }
}