<template>
  <b-container class="mt-3">
    <ol>
      <li v-for="pos in positionsPage" :key="pos._id" :id="pos._id">
        {{pos.properties.ripanoProject}}: {{pos.properties.scanPosition}}
        <router-link
          v-for="pano in pos.properties.panos"
          :key="pano.image"
          :to="`/pano/${pos.properties.ripanoProject}/${pos.properties.scanPosition}/${pano.title}`"
        >
          <img
            class="pano"
            :src="thumbUrl({ ...pos.properties, title: pano.title })"
            :alt="pano.title"
          />
        </router-link>
      </li>
    </ol>
    <jw-pagination :items="positions" @changePage="onChangePage"></jw-pagination>
  </b-container>
</template>
<script>
import request from "../util/request";
import ripano from "../util/ripano";
export default {
  name: "PanosList",
  data() {
    return {
      positions: [],
      positionsPage: [],
      projects: [],
    };
  },
  created() {
    request.get("scanpositions").then(pos => {
      if (pos.data.scanpos && pos.data.scanpos.length) {
        const p = pos.data.scanpos.filter(val => val.properties?.panos?.length > 0);
        // this.positions = p.length > 100 ? p.slice(0, 100) : p;
        this.positions = p;
      }
      this.projects = pos.data.projects;
    });
  },
  methods: {
    thumbUrl(ele) {
      return ripano.thumbUrl(ele);
    },
    onChangePage(positionsPage) {
      this.positionsPage = positionsPage;
    }
  }
};
</script>
<style scoped>
li {
  margin-bottom: 1em;
}
img.pano {
  width: 100px;
  height: 100px;
  margin-left: 10px;
}
</style>