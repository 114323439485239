import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/HomePage'
// import store from './store'
import Map from '@/components/MapComponent'
import Login from '@/components/LoginPage'
import LoginLocal from '@/components/LoginLocalPage'
import MarkerList from '@/components/MarkerList'
import ProjectList from '@/components/ProjectList'
import Admin from '@/components/AdminPage'
import Info from '@/components/InfoPage'
import Users from '@/components/UsersPage'
import Pano from '@/components/PanoComponent'
import Panos from '@/components/PanosList'
import MapProject from '@/components/MapProject'
import ImportPage from '@/components/ImportPage'
import Ripano from '@/components/RipanoIframe'
import EditMarker from '@/components/EditMarker'
import Projections from '@/components/ProjectionPage'
import ChangePassword from '@/components/ChangePassword'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'Home', component: Home },
    { path: '/login', name: 'Login', component: Login },
    { path: '/loginLocal', name: 'LoginLocal', component: LoginLocal },
    { path: '/marker', name: 'Marker', component: MarkerList, meta: { requiresAuth: true } },
    { path: '/marker/:id', name: 'MarkerId', component: MarkerList, meta: { requiresAuth: true } },
    { path: '/marker/edit/:id', name: 'EditMarker', component: EditMarker, meta: { requiresAuth: true } },
    { path: '/map', name: 'Map', component: Map, meta: { requiresAuth: true } },
    { path: '/map/:id', name: 'MapProject', component: MapProject, meta: { requiresAuth: false } },
    { path: '/map/:id/:marker', name: 'MapProjectMarker', component: MapProject, meta: { requiresAuth: false } },
    { path: '/projects', name: 'Projects', component: ProjectList, meta: { requiresAuth: true } },
    { path: '/admin', name: 'Admin', component: Admin, meta: { requiresAuth: true, needsRole: 'admin' } },
    { path: '/users', name: 'Users', component: Users, meta: { requiresAuth: true } },
    { path: '/info', name: 'Info', component: Info },
    { path: '/import', name: 'Import', component: ImportPage },
    { path: '/panos', name: 'Panos', component: Panos },
    { path: '/projections', name: 'Projections', component: Projections },
    { path: '/pano/:ripanoProject/:scanPosition/:title', name: 'Pano', component: Pano },
    { path: '/fullpano/:p/:sp/:vt/:phi/:theta/:fov', name: 'Ripano', component: Ripano },
    { path: '/changepassword', name: 'ChangePassword', component: ChangePassword },
  ],
  scrollBehavior: function (to) { if (to.hash) { console.log('scroll to ', to.hash); return { selector: to.hash } } }
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.needsRole)) {
//     if (store.getters.hasRole(to.meta.needsRole)) {
//       next();
//       return;
//     } else {
//       console.log('NOT in role ', to.meta.needsRole);
//       next('/');
//       return;
//     }
//     // FIXME
//   } else if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters.isLoggedIn) {
//       next();
//       return;
//     }
//     next('/login')
//   } else {
//     next()
//   }
// })


export default router;