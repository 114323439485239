<template>
  <b-container>
    <b-row>
      <b-col>
        <h3>Benutzer</h3>
      </b-col>
    </b-row>
    <b-form-row v-for="user in users" :key="user._id">
      <b-col>{{ user.email }}</b-col>
      <b-col>
        <b-form-checkbox v-model="user.enabled">aktiv</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-select
          v-model="user.roles"
          :options="roles"
          multiple
          :select-size="2"
          @change="updateUser(user)"
        ></b-form-select>
      </b-col>
      <b-col style="text-align: center">
        <b-button variant="warning" @click="newPassword(user._id)" class="btn-sm"
          >Neues Passwort</b-button
        >
      </b-col>
      <b-col style="text-align: center">
        <b-button variant="danger" @click="deleteUser(user._id)" class="btn-sm"
          >Löschen</b-button
        >
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form>
          <b-form-input
            ref="newusermail"
            type="email"
            v-model="newUser.email"
            @keydown.enter.prevent="addUser"
            placeholder="E-Mail neuer Benutzer"
            id="newuseremail"
          ></b-form-input>
        </b-form>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="newUser.enabled">aktiv</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-select
          v-model="newUser.roles"
          :options="roles"
          multiple
          :select-size="2"
        ></b-form-select>
      </b-col>
      <b-col style="text-align: center">
        <b-button variant="success" @click="addUser" class="btn-sm"
          >Hinzufügen</b-button
        >
      </b-col>
      <b-col></b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import request from "../util/request";
export default {
  name: "AdminPage",
  data() {
    return {
      users: [],
      newUser: { mail: "", enabled: true, roles: ["user"] },
      roles: ["admin", "user"],
    };
  },
  created() {
    this.getUsers();
  },
  computed: {
    // validInput() {
    //   console.log('VALID?', document.querySelector('#newuseremail'));
    //   return false;
    // }
    // isEmail() {
    //   if (this.newUser && this.newUser.user && this.newUser.user.length) {
    //     if (this.validEmail(this.newUser.user)) {
    //       return true;
    //     }
    //     return false;
    //   }
    //   return true;
    // }
  },
  methods: {
    // validEmail: function(email) {
    //   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   return re.test(email);
    // },
    getUsers() {
      request.get("users").then((users) => (this.users = users.data));
    },
    addUser() {
      console.log("add user: ", this.newUser);
      request
        .post("users", this.newUser)
        .then((res) => {
          console.log('add user response: ', res);
          this.$bvToast.toast(
            `Benutzer hinzugefügt: ${this.newUser.email} mit Passwort ${res.data.passwordPlain}  . Bitte senden Sie das Passwort an den Benutzer.`,
            {
              title: "Benutzer hinzugefügt",
              variant: "success",
              solid: true,
            }
          );
          this.getUsers();
          this.newUser = { username: "", email: "", enabled: true, roles: ["user"] };
        })
        .catch((err) => {
          console.log("error: ", err);
          this.$bvToast.toast(`Error adding account: ${JSON.stringify(err.response.data.errors)}`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    updateUser(user) {
      console.log("update user ", user);
      request.patch(`users/${user._id}`, { user: user }).then((val) => {
        console.log("patch res: ", val);
        if (val.data.result && val.data.result.ok) {
          this.$bvToast.toast("Benutzer erfolgreich aktualisiert", {
            title: "Benutzer aktualisiert",
            variant: "success",
            solid: true,
          });
        }
      });
    },
    async newPassword(id) {
      const res = await request.post('users/newPassword', {
        userId: id,
      });
      this.$bvToast.toast(
        `Neues Passwort ${res.data.passwordPlain}  . Bitte senden Sie das Passwort an den Benutzer.`,
        {
          title: "Passwort erfolgreich geändert",
          variant: "success",
          solid: true,
        }
      );
    },
    async deleteUser(id) {
      const val = await this.$bvModal.msgBoxConfirm(
        "Benutzer wirklich löschen?", {
          okTitle: "Löschen",
          okVariant: "danger",
        }
      );
      if (val !== true) {
        return;
      }
      request.delete(`users/${id}`).then((res) => {
        console.log("delete res: ", res);
        if (res?.data?.deletedCount) {
          this.$bvToast.toast("Benutzer erfolgreich gelöscht", {
            title: "Benutzer gelöscht",
            variant: "success",
            solid: true,
          });
          this.getUsers();
        }
      }).catch((err) => {
        console.log("error: ", err);
        this.$bvToast.toast(`Error deleting account: ${JSON.stringify(err.response.data.errors)}`,
          {
            title: "Error deleting account",
            variant: "danger",
            solid: true,
          }
        );
      });
    },
  },
};
</script>