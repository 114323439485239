<template>
  <b-modal
    ref="edit-project"
    id="edit-project"
    title="Projekt bearbeiten"
    @ok="saveProject">
    <label for="name">Name</label>
    <b-form-input
      id="pname"
      v-model="selectedProject.name"
      placeholder="Name"
      type="text"
      class="mb-2"></b-form-input>
    <label for="pdate">Aufnahmedatum</label>
    <b-form-input
      id="pdate"
      v-model="selectedProject.date"
      placeholder="Aufnahmedatum"
      type="date"
      class="mb-2"></b-form-input>
    <label for="pdownloadUrl">Download url</label>
    <b-form-input
      id="pdownloadUrl"
      v-model="selectedProject.downloadUrl"
      placeholder="Download url (optional) https://..."
      type="text"
      class="mb-2"></b-form-input>
    <label for="ppotreeUrl">Potree url</label>
    <b-form-input
      id="ppotreeUrl"
      v-model="selectedProject.potreeUrl"
      placeholder="Potree url (optional) https://..."
      type="text"
      class="mb-2"></b-form-input>
    <label for="pmarkerIcon">Karten Icon</label>
    <b-form-select
      id="pmarkerIcon"
      v-model="selectedProject.markerIcon"
      :options="markerOptions"
      class="mb-2"></b-form-select>
    <div v-if="selectedProject.markerIcon == 'circle'">
      <label for="pmarkerIconColor">Karten Icon Farbe</label>
      <b-form-input
        id="pmarkerIconColor"
        v-model="selectedProject.markerIconColor"
        type="color"
        class="mb-2"></b-form-input>
      <label for="pmarkerIconRadius">Karten Icon Größe</label>
      <b-form-input
        id="pmarkerIconRadius"
        v-model="selectedProject.markerIconRadius"
        min="1"
        max="50"
        type="range"
        class="mb-2"></b-form-input>
    </div>
  </b-modal>
</template>
<script>
import request from "../util/request";

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      selectedProject: {},
      markerOptions: [
        { value: "", text: "Standard Marker" },
        { value: "circle", text: "Kreis" },
      ],
    };
  },
  watch: {
    project: function (val) {
      this.selectedProject = val;
    },
  },
  methods: {
    async saveProject() {
      console.log(this.selectedProject.name);
      const res = await request.patch(`projects/${this.selectedProject._id}`, {
        name: this.selectedProject.name,
        downloadUrl: this.selectedProject.downloadUrl,
        potreeUrl: this.selectedProject.potreeUrl,
        date: this.selectedProject.date,
        markerIcon: this.selectedProject.markerIcon,
        markerIconColor: this.selectedProject.markerIconColor,
        markerIconRadius: this.selectedProject.markerIconRadius,
      });
      if (res.data?.doc?.acknowledged) {
        this.$emit("project-updated", this.selectedProject);
      }
    },
  },
};
</script>
