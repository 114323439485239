<template>
  <b-container class="mt-3">
    <b-form-group class="mb-4">
      <b-input-group size="sm">
        <b-form-input id="filter-input" v-model="filter" type="search"
          placeholder="Suchen"></b-form-input>

        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Löschen</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-pagination v-if="totalRows > perPage" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
      size="sm" class="my-0"></b-pagination>
    <b-table v-if="projects.length" hover :items="projects" :fields="fields"
      :current-page="currentPage" :per-page="perPage" :filter="filter" :filterOn="filterOn">
      <template v-slot:cell(name)="data">
        <b-button v-if="hasAdmin" @click="openProject(data.item)" size="sm"
          variant="outline-secondary">
          <b-icon-pencil></b-icon-pencil>
        </b-button>
        <b-link :href="`/ripano/?p=${data.item.ripanoProject}`" target="ripano">
          {{ data.item.name }}
        </b-link>
      </template>
      <template v-slot:cell(edit)="data">
        <div class="text-right">
          <b-button-group>
            <b-button :to="`/map/${data.item._id}`">
              <b-icon-map></b-icon-map>
            </b-button>
            <b-button :to="`/marker/${data.item._id}`">
              <b-icon-geo-alt></b-icon-geo-alt>
            </b-button>
            <b-button :href="data.item.downloadUrl" target="_blank"
              :disabled="!data.item.downloadUrl">
              <b-icon-download></b-icon-download>
            </b-button>
            <b-button variant="danger" v-if="hasAdmin"
              @click.stop.prevent="deleteProject(data.item)">
              <b-icon-trash></b-icon-trash>
            </b-button>
          </b-button-group>
        </div>
      </template>
    </b-table>
    <div v-else>
      <span variant="info">Keine Projekte gefunden</span>
    </div>
    <project-edit-dialog :project="selectedProject"></project-edit-dialog>
  </b-container>
</template>

<script>
// router-link
// :to="`/map/${project._id}`"
import request from "../util/request";
import {
  BIconMap,
  BIconGeoAlt,
  BIconTrash,
  BIconPencil,
  BIconDownload,
} from "bootstrap-vue";
import moment from "moment";
import ProjectEditDialog from "./ProjectEditDialog.vue";
export default {
  name: "HomePage",
  components: {
    BIconMap,
    BIconDownload,
    BIconGeoAlt,
    BIconTrash,
    BIconPencil,
    ProjectEditDialog
},
  data: function () {
    return {
      projects: [],
      selectedProject: {},
      fields: [
        { key: "name", label: "Name", sortable: true },
        {
          key: "date",
          label: "Aufnahmedatum",
          sortable: true,
          formatter: "formatDate",
        },
        { key: "edit", label: "", sortable: false },
      ],
      filter: null,
      filterOn: ["name"],
      filteredProjects: [],
      projectSearch: "",
      currentPage: 1,
      totalRows: 0,
      perPage: 20,
    };
  },
  computed: {
    hasAdmin() {
      return this.$store.getters.hasRole("admin");
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    openProject(p) {
      console.log("open", p);
      this.selectedProject = p;
      this.$bvModal.show("edit-project");
    },
    async deleteProject(project) {
      const res = await this.$bvModal.msgBoxConfirm(
        `Projekt wirklich löschen?`,
        {
          title: project.name,
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Löschen",
          cancelTitle: "Abbruch",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        },
      );
      if (res) {
        request.delete(`projects/${project._id}`).then(() => {
          this.projects = this.projects.filter((p) => p._id !== project._id);
          this.filteredProjects = this.projects;
        });
      }
    },
  },
  mounted: function () {
    request
      .get("projects")
      .then((projects) => {
        // console.log("all projects for you: ", projects);
        this.projects = projects.data;
        this.filteredProjects = this.projects;
        this.totalRows = this.projects.length;
      })
      .catch((err) => {
        console.log("projects-err: ", err);
      });
  },
};
</script>

<style scoped></style>
