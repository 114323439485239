<template>
<b-container>
  <b-card class="card rounded mt-5">
    <b-card-header class="card-header">
      <div class="titel-textfelder">Login</div>
    </b-card-header>
    <b-card-body>
      <b-button variant="outline-primary" @click="loginMSal">Start Login flow</b-button>
    </b-card-body>
  </b-card>
</b-container>
</template>

<script>
export default {
  name: "LoginPage",
  data: function() {
    return {
      mailSent: false,
      mailError: false,
      mailSending: false,
      passError: false,
      email: "",
      pass: ""
    };
  },
  methods: {
    loginMSal() {
      console.log('Redirect to MS');
      window.location = '/api/auth/signin';
    },
    // loginLocal() {
    //   this.$store
    //     .dispatch("login", { user: this.email, password: this.pass })
    //     .then(succ => {
    //       console.log("login res: ", succ);
    //       this.passError = false;
    //       this.$router.push("/map");
    //     })
    //     .catch(err => {
    //       console.log("err login: ", err);
    //       this.passError = "Ungültiger Benutzername/Passwort";
    //     });
    // }
  }
};
</script>

<style scoped>
</style>

