<template>
  <div>
    <navbar></navbar>
    <!-- <b-container class="mt-3"> -->
      <router-view></router-view>
    <!-- </b-container> -->
    <EkgFooter></EkgFooter>
  </div>
</template>

<script>
import Navbar from "./components/NavbarComponent";
import EkgFooter from "./components/EkgFooter";
export default {
  name: "App",
  async created() {
    await this.$store.dispatch("checkLogin");
    console.log('Logged in ?', this.$store.getters.isLoggedIn, this.$store)
    // console.log("App config: ", this.$appConfig);
  },
  methods: {},
  components: {
    Navbar,
    EkgFooter
  }
};
</script>
<style scoped>
.router-link-active {
  color: lightgray;
}
footer {
  display: flex;
}
.fluid-container.footer > *:last-child {
  margin-bottom: 0px;
}
</style>