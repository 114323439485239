<template>
  <div class="llmap"></div>
</template>
<script>
import * as L from "leaflet";
import { VldmMap } from "../map";

export default {
  name: "inline-map",
  props: ["initialView", "markers"],
  data: function() {
    return {
      map: "",
      svgMarker: '',
      markerLayer: {}
    };
  },
  mounted() {
    this.initMap();
    if (this.initialView) {
      this.setView(this.initialView);
    }
    this.svgMarker = require('../assets/beenhere.svg');
    // console.log('add markers: ', this.markers, this.svgMarker);
    this.updateMarkers();
  },
  destroyed() {
    this.map.map.remove();
  },
  methods: {
    initMap() {
      this.markerLayer = new L.FeatureGroup();
      this.map = new VldmMap(document.querySelector(".llmap"),{
        Vue: this
      });
      // this.map.setupMap(this.$appConfig)
      this.map.addProjects();
      // setupMap(this.map, this.$appConfig);
      // this.markerLayer.addTo(this.map);
      // layerCtrl.addOverlay(this.markerLayer, "Marker");
    },
    setView(v) {
      this.map.map.setView([v.coordinates[1], v.coordinates[0]], 15);
    },
    updateMarkers() {
      this.markerLayer.clearLayers();
      L.geoJson(this.markers, {
        pointToLayer: this.makeMarker
      }).addTo(this.markerLayer);
    },
    makeMarker(feature, latlng) {
      const myMarker = L.marker(latlng, {
        icon: L.icon({
          iconUrl: require('../assets/beenhere.png'),
          iconSize: [25, 25], // size of the icon
          iconAnchor: [10, 14], // point of the icon which will correspond to marker's location
          popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
        })
      });

      let pop = `<h3>${feature.properties.title}</h3>
      <p>${feature.properties.description || ""}</p>
      `;
      if (feature.properties.attachments) {
        for (const attach of feature.properties.attachments) {
          if (attach.thumbnail) {
            pop += ` <img style='width:200px; height:200px' src="data:${attach.mime};base64,${attach.thumbnail}" /> `;
          }
        }
      }
      pop += `<footer><a target="blank" href="${feature.properties.url}">Ripano</a></footer>`;
      myMarker.bindPopup(pop);
      // console.log("added marker: ", myMarker);
      return myMarker;
    }
  },
  watch: {
    markers: function() {
      this.updateMarkers();
    }
  },
  // components: {
  //   BeenHere
  // },
};
</script>
<style scoped>
.llmap {
  width: 100%;
  height: 60vh;
  margin: 1em auto;
}
</style>