

export default {
  ripanoFullUrlFromMarkerProperties(m) {
    return `/ripano/?p=${m.ripanoProject}&sp=${m.ripanoView.sceneInfo.scene}&vt=${m.ripanoView.sceneInfo.view}&phi=${m.ripanoView.viewSettings.phi}&theta=${m.ripanoView.viewSettings.theta}&fov=${m.ripanoView.viewSettings.fov}&v=pano`;
  },
  ripanoFullUrl(ele) {
    return `/ripano/?p=${ele.p}&sp=${ele.sp}&vt=${ele.vt}&phi=${ele.phi}&theta=${ele.theta}&fov=${ele.fov}&v=pano`;
  },
  ripanoUrl(ele) {
    return `/ripano/?p=${ele.ripanoProject}&sp=${ele.scanPosition}&vt=${ele.title}&phi=60&theta=0&fov=90&v=pano`;
  },
  thumbUrl(ele) {
    return ele.title === "Camera"
      ? `/ripano/Projects/${ele.ripanoProject}/panos/${ele.scanPosition}.tiles/thumb.jpg`
      : `/ripano/Projects/${ele.ripanoProject}/panos/${ele.scanPosition}-${ele.title}.tiles/thumb.jpg`;
  },
  panoUrl(ele) {
    return ele.title === "Camera"
      ? `/ripano/Projects/${ele.ripanoProject}/panos/${ele.scanPosition}.tiles/pano_{f}.jpg`
      : `/ripano/Projects/${ele.ripanoProject}/panos/${ele.scanPosition}-${ele.title}.tiles/pano_{f}.jpg`;
  }
}