import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted'
// import VueHighlightJS from 'vue-highlightjs'

import './assets/css/ekg.scss'

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

Vue.config.productionTip = false
Vue.use(Toasted, {
  duration: 3000,
});
// Vue.use(VueHighlightJS)
window.Vue = Vue;
Vue.router = router;
new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
