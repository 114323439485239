<template>
<b-container>
  <b-card class="card rounded mt-5">
    <b-card-header class="card-header">
      <div class="titel-textfelder">Login</div>
    </b-card-header>
    <b-card-body>
      <b-input-group prepend="Benutzername">
        <b-form-input
          v-model="email"
          type="email"
          placeholder="Benutzername"
        ></b-form-input>
        <b-form-input
          v-model="pass"
          type="password"
          placeholder="Passwort"
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="outline-primary" @click="loginLocal">Login</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card-body>
  </b-card>
</b-container>
</template>

<script>
export default {
  name: "LoginLocalPage",
  data: function() {
    return {
      mailSent: false,
      mailError: false,
      mailSending: false,
      passError: false,
      email: "",
      pass: ""
    };
  },
  methods: {
    loginLocal() {
      this.$store
        .dispatch("login", { email: this.email, password: this.pass })
        .then(succ => {
          console.log("login res: ", succ);
          this.passError = false;
          this.$router.push("/map");
        })
        .catch(err => {
          console.log("err login: ", err);
          this.passError = "Ungültiger Benutzername/Passwort";
        });
    }
  }
};
</script>

<style scoped>
</style>

