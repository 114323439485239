<template>
  <b-container class="my-3 px-0">
    <b-row>
      <b-col>
        <h2>Marker</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-3">
        <h4>Projekt</h4>
      </b-col>
      <b-col>
        <b-form-select
          :options="filterProjects"
          @change="changeProject($event)"
          v-model="activeProject"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Textsuche"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          align="fill"
          :per-page="perPage"></b-pagination>
      </b-col>
      <b-col>
        <b-form-group
          label="Pro Seite"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-for="per-page-select">
          <b-form-select
            v-model="perPage"
            id="per-page-select"
            :options="perPageOptions"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          striped
          hover
          bordered
          class="mt-3"
          :current-page="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :fields="fields"
          :items="markers"
          :filter="filter"
          :filter-function="customFilterMarker"
          @filtered="onFiltered">
          <template v-slot:cell(icon)="data">
            <img
              :src="BeenHere"
              alt="Marker"
              v-if="
                data.item.properties.icon === 'beenhere' ||
                data.item.properties.icon === '' ||
                !data.item.properties.icon
              " />
            <b-icon-info-circle
              :icon="data.item.properties.icon"
              v-else-if="
                data.item.properties.icon === 'info-circle'
              "></b-icon-info-circle>
            <b-icon-plus-circle
              :icon="data.item.properties.icon"
              v-else-if="
                data.item.properties.icon === 'plus-circle'
              "></b-icon-plus-circle>
            <b-icon-exclamation-circle
              :icon="data.item.properties.icon"
              v-else-if="
                data.item.properties.icon === 'exclamation-circle'
              "></b-icon-exclamation-circle>
            <b-icon-camera-fill
              :icon="data.item.properties.icon"
              v-else-if="
                data.item.properties.icon === 'camera-fill'
              "></b-icon-camera-fill>
          </template>
          <template v-slot:cell(name)="data">
            <a
              v-if="data.item.properties.link"
              :href="data.item.properties.link"
              target="_blank">
              <b-icon-link></b-icon-link> {{ data.item.properties.name }}
            </a>
            <span v-else>{{ data.item.properties.name }}</span>
          </template>
          <template v-slot:cell(tags)="data">
            <b-badge
              v-for="tag in data.item.properties.tags"
              :key="tag"
              variant="primary"
              :pill="true">
              {{ tag }}
            </b-badge>
          </template>
          <template v-slot:cell(edit)="data">
            <b-button
              v-if="$store.getters.hasRole('admin')"
              @click="
                $router.push({
                  name: 'EditMarker',
                  params: { id: data.item._id },
                })
              ">
              <b-icon-pencil-square></b-icon-pencil-square>
            </b-button>
          </template>
          <template v-slot:cell(viewer)="data">
            <b-button-group>
              <b-button @click="showRipano(data.item)">
                <b-icon-badge-3d></b-icon-badge-3d>
              </b-button>
              <b-button
                class="ml-2"
                @click="
                  $router.push({
                    name: 'MapProject',
                    params: {
                      id: data.item.properties.projectId,
                      marker: data.item._id,
                    },
                  })
                ">
                <b-icon-map></b-icon-map>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import moment from "moment";
import BeenHere from "../assets/mapicons/beenhere.svg";
import {
  BIconLink,
  BIconBadge3d,
  BIconPencilSquare,
  BIconMap,
  BIconInfoCircle,
  BIconPlusCircle,
  BIconExclamationCircle,
  BIconCameraFill,
} from "bootstrap-vue";
export default {
  name: "MarkerTable",
  components: {
    BIconLink,
    BIconBadge3d,
    BIconPencilSquare,
    BIconMap,
    BIconInfoCircle,
    BIconPlusCircle,
    BIconExclamationCircle,
    BIconCameraFill,
  },
  props: ["allMarkers", "showTrashed", "allProjects", "preSelectedProject"],
  data: function () {
    return {
      BeenHere,
      filter: "",
      markers: [],
      fields: [
        { key: "name", label: "Name", sortable: true },
        { key: "icon", label: "Icon" },
        { key: "tags", label: "Tags" },
        { key: "properties.description", label: "Kommentar" },
        {
          key: "properties.modifiedBy.email",
          label: "Benutzer",
          sortable: true,
        },
        {
          key: "properties.modifiedAt",
          label: "Datum",
          sortable: true,
          formatter: (value) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
        { key: "viewer", label: "Viewer" },
        { key: "edit", label: "Edit" },
      ],
      searchName: "",
      searchUser: "",
      searchTags: "",
      filterProjects: [],
      activeProject: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 20, 50, 100],
    };
  },
  watch: {
    preSelectedProject: function (newProject) {
      this.activeProject = newProject;
      this.filterProject();
    },
    allProjects: function (newProjects) {
      this.filterProjects = newProjects.map((val) => {
        return { value: val._id, text: val.name };
      });
      this.filterProject();
    },
    allMarkers: function (newMarkers) {
      this.markers = newMarkers;
      this.totalRows = this.markers.length;
    },
    searchName: function (search) {
      this.textSearch(search, "name");
    },
    searchUser: function (search) {
      this.textSearch(search, "email");
    },
    searchTags: function (search) {
      if (search && search.length > 1) {
        this.markers = this.allMarkers.filter((marker) => {
          return marker.properties?.tags?.some((tag) => {
            return tag.toLowerCase().includes(search.toLowerCase());
          });
        });
      } else {
        this.markers = this.allMarkers;
      }
    },
  },
  methods: {
    showRipano(marker) {
      const params = {
        p: marker.properties.ripanoProject,
        sp: marker.properties.ripanoView.sceneInfo.scene,
        vt: marker.properties.ripanoView.sceneInfo.view,
        phi: marker.properties.ripanoView.viewSettings.phi,
        theta: marker.properties.ripanoView.viewSettings.theta,
        fov: marker.properties.ripanoView.viewSettings.fov,
      };
      console.log("show rip with params", params);
      this.$router.push({
        name: "Ripano",
        params,
      });
    },
    customFilterMarker(row, filter) {
      return (
        row.properties.name.toLowerCase().includes(filter.toLowerCase()) ||
        row.properties?.tags?.some((tag) => {
          return tag.toLowerCase().includes(filter.toLowerCase());
        }) ||
        row.properties.description
          ?.toLowerCase()
          .includes(filter.toLowerCase()) ||
        row.properties.modifiedBy?.email
          ?.toLowerCase()
          .includes(filter.toLowerCase())
      );
    },
    lastMod(date) {
      return moment(date).fromNow();
    },
    // prevent_nl(ev) {
    //   ev.preventDefault();
    //   // console.log('try to enter newline: ', ev.target);
    // },
    textSearch(search, prop) {
      if (search && search.length > 1) {
        const re = new RegExp(search, "i");
        this.markers = this.allMarkers.filter((val) => {
          if (prop === "email") {
            return re.test(val.properties.modifiedBy.email);
          }
          return re.test(val.properties[prop]);
        });
      } else {
        this.markers = this.allMarkers;
      }
    },
    changeProject() {
      this.$emit("project-changed", this.activeProject);
      this.filterProject();
    },
    filterProject() {
      console.log("filter project: ", this.activeProject);
      if (this.activeProject === null) {
        this.markers = this.allMarkers;
      } else {
        this.markers = this.allMarkers.filter(
          (val) => val.properties.projectId === this.activeProject,
        );
      }
    },
    onFiltered(filteredItems) {
      console.log("ONFILTERED: ", filteredItems);
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  // mounted() {
  // },
  created() {
    this.markers = this.allMarkers;
    this.filterProjects = this.allProjects.map((val) => {
      return { value: val._id, text: val.name };
    });
    this.filterProjects.unshift({ value: null, text: "Bitte auswählen" });
    console.log("preselected project: ", this.preSelectedProject);
    this.activeProject = this.preSelectedProject;
    this.filterProject();
  },
};
</script>
<style lang="scss" scoped>
.showTrashed {
  color: black;
}
.hideTrashed {
  color: lightgray;
}
td .badge {
  margin-right: 0.5em;
}
</style>
