import Vue from 'vue'
import Vuex from 'vuex'
import request from '../util/request'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    system: {},
    user: {}
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, data) {
      console.log('auth_success', data.user);
      state.status = 'success'
      state.user = data.user
      state.system = data.system
      console.log('user: ', state.user, data.user);
    },
    auth_error(state) {
      state.status = 'error'
    },
    reg_success(state, email) {
      state.user = {
        email: email
      }
    },
    reg_error(state) {
      state.status = 'error'
    },
    set_user(state, user) {
      state.user = user;
    },
    logout(state) {
      state.status = ''
      state.user = ''
    },
  },
  actions: {
    logged_in({ commit }, user) {
      console.log('action _login: ', user);
      commit('auth_success', user)
    },
    checkLogin({ commit }) {
      console.log('action check_login');
      return request.get('users/id').then(res => {
        console.log('action check_login res: ', res);
        commit('auth_success', res.data)
      }).catch(err => {
        console.log('action check_login err: ', err);
        commit('auth_error')
      })
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        request.post('users/login', user)
          .then((succ) => {
            console.log('store login: ', succ.data);
            if (!succ.data?.user) {
              console.log('store login err: ', succ.data);
              reject(succ);
            } else {
              console.log('COMMITTING to : ', succ.data.user);
              commit('auth_success', { user: succ.data.user})
              resolve(succ.data)
            }
          })
          .catch(err => {
            console.log('store err login: ', err);
            commit('auth_error')
            reject(err)
          })
      })
    },
    logout({ commit }) {
      commit('logout')
      return request.post('users/logout');
    },
  },
  getters: {
    isLoggedIn: state => state.user?.email,
    authStatus: state => state.status,
    isAdmin: state => state.user?.roles?.includes('admin'),
    user: state => state.user,
    name: state => state.user?.email,
    system: state => state.system,
    localAuth: state => state.system?.localAuth,
    hasRole: (state) => {
      return (role) => {
        // console.log('Roles available: ', roles, roles?.includes(role));
        return state.user?.roles?.includes(role);
      }
    },
  }
})
